<template>
  <h2 class="title">DONATIVOS</h2>
  <div class="donation">
    <h2>RECAUDACIÓN</h2>
    <div class="progress">
      <span class="label from">0%</span>
      <span class="label to">100%</span>
      <div class="bar"><span>80%</span></div>
    </div>
    <p><strong>6 meses</strong> para llegar a la meta </p>
  </div>
</template>

<script>
export default {
  name: 'DonationModule',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.donation { background:#404246; color:#fff; border-radius:12px; padding:5px 15px; padding-bottom:20px;}
.donation p { margin-top:35px; margin-bottom:0px;}
.donation p  strong { color:#F6CD1F; font-size:14px; }
.donation h2 {text-align:left; font-size:16px; }
.donation .progress { background:#fff; border-radius:100px; width:100%; height:30px; position:relative; }
.donation .progress span.label { position:absolute; color:#fff; bottom:5px; font-size:14px;}
.donation .progress span.label.from { left:0; bottom:-30px; }
.donation .progress span.label.to { right:0; bottom:-30px; }
.donation .progress .bar { background-color:#00B981; width:50%; height:30px; position:absolute; top:0; border-radius:100px;}
.donation .progress .bar span { position:absolute; right:-40px; color:#B8B8B8; top:5px;}
</style>

<template>
<h2 class="title">¿QUÉ ES UNNA?</h2>
<p class="about">{{ this.about.acerca }}</p>
<br/>
<h2 class="title">OBJETIVO GENERAL</h2>
<h3 class="main-goal">{{ this.about.general }}</h3>
  <br/>
  <h2 class="title">OBJETIVOS ESPECÍFICOS</h2>
  <div class="goals">
    <div class="goal" @click="open" v-for="(goal, index) in this.goals" :key="index">
      <div class="meta">
        <h2><span>{{goal.idx}}</span></h2>
        <p>{{goal.descripcion}}</p>
      </div>
      <a href="javascript:void(0)" class="expand"><i data-feather="chevron-down"></i></a>
      <div class="list">
        <h3>METAS</h3>
        <ul>
          <li v-for="(item, index) in goal.metas" :key="index">
            <div class="data">
              <div class="status"><i data-feather="clock"></i></div>
              <div class="number">{{item.idx}}</div>
            </div>
            <div class="info">
              <i data-feather="chevron-right"></i>
              <router-link :to="{ name: 'acciones', params: { filter: 'M'+item.idx }}">
                {{item.meta}}
              </router-link>
            </div>
          </li>

        </ul>
      </div>
      <div class="progress">
        <div class="row">
          <div class="col"><h4>0%</h4></div>
          <div class="col"><div class="trackbar"><span class="load" :style="{ width : goal.porcentaje+'%' }"></span></div></div>
          <div class="col"><h4>100%</h4></div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import feather from 'feather-icons'

export default {
  name: 'GoalModule',
  props: {
    msg: String
  },
  data: () => (
    { nav : '', about: [], goals:[] }
  ),
  methods : {
     open : function(event){
       let el = event.currentTarget;

       if(el.classList.contains('open')){
           el.classList.remove("open");
       }else{
         el.classList.add("open");
       }
    },

    getInfo(){
      this.$axios.get('https://app.earlyinstitute.org/api/index.php')
        .then(response => {
           this.about = response.data.acerca;
           this.goals = response.data.objetivos;
           console.log(this.goals);

      })
      .catch(error => {
        console.error("Error de API:", error);
      });
      },
  },
  mounted(){

    this.getInfo();
    feather.replace();

  },
  updated(){
    feather.replace();
  },
}
</script>

<style scoped>
p.about { color:#FF6F64; font-size:18px; text-align:left; font-weight:500;}
h3.main-goal { color:#FF6F64; font-size:26px; text-align:left; line-height:30px; margin:0; margin-bottom:25px; }
.goals { margin:auto; padding-bottom:100px; }
.hidden { display:none; }
.goals .goal { border:#E4E4E4 solid 1px; border-radius:12px; background:#EAEAEA; overflow:hidden;position:relative; margin-bottom:30px; cursor:pointer;}
.goals .goal a.expand { position:absolute; right:2px;  margin:auto; border-radius:100px; bottom:40px; width:26px; height:26px; background:#FF6F64; color:#fff; line-height:40px; display:block; z-index:1000;}
.goals .goal a.expand svg { width:22px; height:22px;}
.goals .goal .meta { position:relative; min-height:80px; padding-bottom:10px; }
.goals .goal .meta p { margin:0; padding:10px; padding-left:70px; text-align:left; font-size:14px;}
.goals .goal .meta h2 { margin:0; position:absolute; background:#FF6F64; color:#fff; width:60px; top:1px; bottom:0px;left:1px; border-top-left-radius:12px; font-size:30px; font-weight:400;}
.goals .goal .meta h2 span { height:100%; width:100%;  display:flex; align-items: center; justify-content: center; }
.goals .goal .list { padding:2px; transition: all 0.5s ease; height:0px; opacity:0;}
.goals .goal.open .list { opacity:1; height:auto;}
.goals .goal .list h3 { margin:0; color:#fff; font-size:12px; background:#00B981; letter-spacing:.08em; text-align:left; text-indent:10px; padding:5px 0px;}
.goals .goal .list ul { margin:0; padding:0; list-style:none;  margin-top:2px;}
.goals .goal .list ul li { margin-bottom:5px; background:#fff;}
.goals .goal .list ul li:last-child { margin-bottom:2px;}
.goals .goal .list ul li .info { padding-left:60px; text-align:left; padding-right:10px; padding-top:0px; padding-bottom:0px; font-size:14px; vertical-align:middle; min-height:66px;}
.goals .goal .list ul li .info a { color:#2c3e50; padding-right:30px; display:block; padding:10px;}
.goals .goal .list ul li .info svg { color:#ccc; position:absolute; right:5px;  width:18px; height:18px;}
.goals .goal .list ul li .data { background:#7D7D7D; width:50px; position:absolute; }
.goals .goal .list ul li .data .status { min-height:33px; padding-top:6px; color:#B8B8B8;}
.goals .goal .list ul li .data .number { background:#404246; color:#fff; min-height:33px; padding-top:6px; }
.goals .goal .progress { background:#404246; padding-top:15px; padding-bottom:15px; border:#E4E4E4 solid 1px; border-top:none;}
.goals .goal .progress .trackbar { min-height:10px; background-color:#e4e4e4; width:98%; border-radius:100px; position:relative; overflow:hidden; }
.goals .goal .progress h4 { color:#fff; font-size:12px; text-align:center; padding:0; margin:0;}
.goals .goal .progress .trackbar span.load { background:#00B981; width:50%; min-height:10px; position:absolute; left:0; top:0;}
.goals .goal .progress .row { width:98%;}
.goals .goal .progress .row .col { display:inline-block; }
.goals .goal .progress .row .col:nth-child(1) { width:10%; }
.goals .goal .progress .row .col:nth-child(2) { width:80%; }
.goals .goal .progress .row .col:nth-child(1) { width:10%; }
</style>

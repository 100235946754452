<template>
  <router-view/>
</template>
<style>
* { outline:none; -webkit-tap-highlight-color: transparent;  box-sizing: border-box;}
body {   background:#FBFBFB; margin:0; padding:0;}
html { scroll-behavior: smooth;}
h1 { margin:0; padding:0; padding-top:30px; }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

h3.title-s { margin:0; padding:0; font-size:14px; font-weight:bold; color:#7D7D7D; letter-spacing:.05em; text-align:left; margin-bottom:15px;}

a { text-decoration:none;}

section.hero {
  background: linear-gradient(45deg, #7D7D7D 0%, #4D4D4D 100%);
  min-height:125px;
  padding-top:65px;
  padding-bottom:30px;
}
section.page { padding:20px; }
section.hero h1 { color:#fff; letter-spacing:.10em; font-size:20px; font-weight:300;}

h2.title { font-size:12px; text-align:left; letter-spacing:.05em; color:#7D7D7D;}

</style>

<script>

import feather from 'feather-icons'
export default {
  name: 'App',
  components: {},

  props: {
    msg: String
  },
  methods:{

   },
   mounted(){
    feather.replace();
   },
}
</script>

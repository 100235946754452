<template>
  <HeaderModule/>
<main>
<section class="hero">
<h1>RESULTADOS</h1>
</section>

<section class="page">
  <div class="wrapper">

  <DonationModule msg="Welcome to Your Vue.js App"/>
  <br>
  <GoalModule msg="Welcome to Your Vue.js App"/>
  <br>
  <div class="stats">
    <h2>ESTADÍSTICAS ANUALES</h2>
  </div>
  <div class="results">
    <h2>DATOS GLOBALES</h2>
  </div>

  </div>

</section>
<footer>
  <nav class="sub">
  <router-link class="btn" to="/resultados">Resultados</router-link>
  <router-link class="btn" to="/acerca">Acerca de</router-link>
  <router-link class="btn" to="/acciones">Acciones</router-link>
  </nav>
</footer>
</main>


</template>

<script>
import HeaderModule from '@/components/HeaderModule.vue'
import DonationModule from '@/components/DonationModule.vue'
import GoalModule from '@/components/GoalModule.vue'


export default {
  data: () => ({ nav : '', resultados : [] }),

  name: 'HomeView',
  components: {
    DonationModule,GoalModule,HeaderModule
  },
  methods:{
   showNav:function(){
      if(this.nav=="on"){
        this.nav='';
        return;
      }
       this.nav = 'on';
   },



   },
   mounted(){
     this.getResultados();
   },
}
</script>


<style scoped>
main {  margin:auto; position:relative; }
main section.page { max-width:768px; margin:auto;}


section.hero {
  background: linear-gradient(90deg, #FF6F64 0%, #FF8C85 100%);
  min-height:60px;
  padding-bottom:30px;
}
section.hero h1 { color:#fff; letter-spacing:.10em;}
section.page { padding:20px; }
</style>

<template>
  <main>
    <div class="flex">

    <img src="@/assets/logo-early.png"/>
    <br/>
    <br><br>
    <form>
    <input type="text" placeholder="usuario" v-model="user" />
    <input type="password" placeholder="contraseña" v-model="pass" />
    </form>
    <a class="btn" @click="auth">Iniciar Sesión</a>
      </div>




      <footer><p><a @click="install" href="#">earlyinstitute.org</a></p></footer>
</main>

</template>

<script>

export default {
  data: () => ({ nav : '', results: {}}),

  name: 'LoginView',
  components: {},
  methods:{

  async install() {
    this.deferredPrompt.prompt();
  },


   showNav:function(){
      if(this.nav=="on"){
        this.nav='';
        return;
      }
       this.nav = 'on';
   },
   auth: function(){
     if(this.user == "" || this.user == null){ alert("ingrese su usuario"); return; }
     if(this.pass == "" || this.pass == null){ alert("ingrese su contraseña"); return; }
     this.$axios.post('/auth.php',{ user: this.user, pass: this.pass },{
     headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
     }})
       .then(response => {
       this.results = response.data;
       if(this.results.status==200){
         localStorage.setItem("userToken",this.results.user.data.user_pass);
         this.$router.push({ path: '/resultados' });
       }else{
         alert("Error! Usuario y/o contraseña invalido.");
         localStorage.removeItem("userToken");
       }
     }).catch(error => {
       console.error("Error de API:", error);
     });
   }
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
  },
}
</script>


<style scoped>
main {  background:#404246; color:#fff; }
main a.btn { border-radius:100px; padding:10px 25px; border:#00B981 solid 2px; color:#fff;}

.flex {
min-height:100vh;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
flex-wrap: wrap;

}

.flex img { max-width:150px; display:block;}

form { width:60%; text-align:center;}
form input { background:#fff; border-radius:100px; border:none; height:45px; display:block; margin-bottom:20px; width:100%; text-indent:15px;}
footer { position:absolute; bottom:30px; left:0; right:0;}
footer a { color:#999; font-weight:600;}
</style>

<template>
<header>

  <router-link to="/resultados" class="logo"><img src="@/assets/logo-early.png"></router-link>

  <router-link to="/notificaciones" class="bell"><i data-feather="bell"></i><span>2</span></router-link>

  <a href="javascript:void(0)" class="menu" @click="showNav()"><img src="@/assets/circle-unna.png"></a>

    <nav :class="nav">
  <h3>PROYECTOS</h3>
  <ul>
    <li><a href="javascript:void(0)"><router-link to="/resultados">UNNA</router-link></a></li>

  </ul>
  <h3>SECCIONES</h3>
  <ul>
    <li><router-link to="/notificaciones">NOTIFICACIONES</router-link></li>
    <li><router-link to="/documentos">DOCUMENTOS CLAVE</router-link></li>
    <li><router-link to="/">CERRAR SESIÓN</router-link></li>
  </ul>
  </nav>

</header>
</template>

<script>
import feather from 'feather-icons'

export default {
  name: 'HeaderModule',
  components: {

  },
  data: () => ({ nav : '', }),

  props: {
    msg: String
  },
  methods:{
   showNav:function(){
      if(this.nav=="on"){
        this.nav='';
        return;
      }
       this.nav = 'on';
   }
   },
   mounted(){
    feather.replace();
   },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

section.hero {
  background: linear-gradient(90deg, #FF6F64 0%, #FF8C85 100%);
  min-height:60px;
  padding-bottom:30px;
}
section.hero h1 { color:#fff; letter-spacing:.10em;}
header a.logo { position:absolute; left:10px; top:5px; width:80px; display:block; }
header a.logo img { max-width:80px; }
header { height:65px; background:#404246; position:fixed; left:0; right:0; top:0; z-index:10000; }
header a.menu { border:#fff solid 2px; width:45px; height:45px; border-radius:100px; display:block; position:absolute; top:5px; right:5px;}
header a.menu img {width:100%;}
header a.bell { position:absolute; color:#fff; right:76px; top:22px;}
header a.bell span { font-size:11px; background:#F6CD1F; color:#333; width:18px; height:18px; border-radius:100px; display:block; position:absolute; top:-12px; right:-14px; line-height:19px; font-weight:bold}

nav { padding-right:20px; padding-top:50px;}
nav ul { margin:0; padding:0; list-style:none; }
nav ul li { margin-bottom:5px; margin-top:5px;}
nav ul li a { color:#fff; display:block;text-align:right; padding:25px; padding-right:0px; font-weight:bold; font-size:14px; letter-spacing:.08em;}
nav h3 { color:#7D7D7D; text-align:right; font-size:14px; letter-spacing:.10em;}
nav { position:fixed; top:0px; background:#333; width:80%; top:64px; bottom:0; right:-100%; z-index:1000;}
nav.on { right:0}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'feather-icons/dist/feather.min.js'
import axiosInstance from './plugins/axios';

const app = createApp(App).use(router);
app.config.globalProperties.$axios = axiosInstance;
app.mount('#app');
